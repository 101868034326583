<template>
  <div class="bg-[#333333]">
    <div class="h-104px flex justify-between items-center max-w-1200px mx-auto">
      <div class="flex items-center items-center">
        <img src="@/assets/1.png" class="mr-8px" />
        <div class="text1">广州民航职业技术学院</div>
      </div>
      <div class="text2">
        <span class="mr-32px">主办单位：广州市旗鱼软件科技有限公司</span>
        <a href="https://beian.miit.gov.cn/">粤ICP备17148249号-6</a>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.text1 {
  font-size: 24px;
  font-family: PangMenZhengDao, PangMenZhengDao-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #ffffff;
  line-height: 24px;
}
.text2 {
font-size: 14px;
font-family: PingFang SC, PingFang SC-Regular;
font-weight: 400;
text-align: LEFT;
color: #999999;
line-height: 14px;
}
</style>
