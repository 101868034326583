<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-02-16 10:17:00
 * @LastEditTime: 2023-02-17 14:14:26
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="bg-[#f8f9fa]">
    <div class="max-w-1200px mx-auto flex h-104px items-center justify-between">
      <div class="flex items-center">
        <img src="@/assets/1.png" class="mr-8px" />
        <div class="text1">广州民航职业技术学院<br />实训平台门户</div>
      </div>
      <div class="flex items-center">
        <nav>
          <router-link class="mr-40px text2" :class="{active: route.meta.name == '首页'}" to="/">首页</router-link>
          <router-link class="mr-40px text2" :class="{active: route.meta.name == '新闻公告'}" to="/newsPage">新闻公告</router-link>
          <router-link class="mr-40px text2" :class="{active: route.meta.name == '如何使用'}" to="/use">如何使用</router-link>
        </nav>
        <div class="bg-[#ccc] w-1px h-22px mr-40px"></div>
        <a href="/admin" class="mr-40px text2">实训管理系统</a>
        <a href="/ewa" class="text2">虚拟仿真实训教学平台</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
const route = useRoute()

console.log(route)

</script>

<style lang="scss" scoped>
.text1 {
  font-size: 19px;
  font-family: PangMenZhengDao, PangMenZhengDao-Regular;
  font-weight: 600;
  color: #333333;
  line-height: 27px;
  text-align: left;
}
.text2 {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  line-height: 16px;
  &.active {
    color:#2F54EB
  }
}
</style>
