/*
 * @Description: 
 * @Author: 
 * @Date: 2023-02-16 09:57:42
 * @LastEditTime: 2023-02-17 14:14:53
 * @LastEditors: Please set LastEditors
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '../views/layout.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Layout,
    redirect: '/indexPage',
    children: [
      {
        path:'indexPage',
        name: 'indexPage',
        component: () => import('@/views/indexPage/index.vue'),
        meta: {
          name: '首页'
        }
      },
      {
        path:'newsPage',
        name: 'newsPage',
        component: () => import('@/views/newsPage/index.vue'),
        meta: {
          name: '新闻公告'
        }
      },
      {
        path:'use',
        name: 'use',
        component: () => import('@/views/howUse/index.vue'),
        meta: {
          name: '如何使用'
        }
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
